import React from 'react';
import { Layout } from '../../Layout';

// import Video from '../../assets/videos/video.mp4';
import styled from 'styled-components';

const Documental = () => {
  return (
    <Layout title="Documental">
      <Container>
        {/* <video controls>
          <source src={Video} type="video/mp4" />
          <track src="" kind="captions" srclang="en" label="english_captions" />
          <track src="" kind="captions" srclang="es" label="spanish_captions" />
        </video> */}

        <iframe
          src="https://www.youtube.com/embed/QGhXM3us2lo?si=VVUU55UHowh-H99X"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          className="full-width"
        ></iframe>

        <p>
          Como parte del proyecto realizamos un micro documental que registra la
          experiencia como complemento de nuestra exhibición fotográfica en el
          Museo Martín Gusinde en Puerto Williams y Fundación Hach Saye en
          Porvenir.
        </p>

        <hr />
      </Container>
    </Layout>
  );
};

export default Documental;

const Container = styled.div`
  & > video {
    width: 100%;
  }

  & > .full-width {
    width: 100%;
    height: 500px;
  }

  & > p {
    text-align: justify;
  }
`;
